<template>
  <div class="form huawei">
    <el-form :model="driverOptions" ref="driverOptions" size="mini">
      <el-form-item
        v-for="(item, index) in formObjDriver"
        :key="index"
        :label="item.label"
        :prop="item.name"
        :rules="item.rules"
        :placeholder="item.placeholder"
        style="margin: 0 auto; width:350px;margin-bottom:20px;"
      >
        <!-- 单选框按钮组 -->

        <el-radio-group
          v-if="item.type === 'radio-group'"
          v-model="driverOptions[item.name]"
          size="large"
        >
          <el-radio-button
            v-for="(radio, index) in selectsOptions[item.name]"
            :key="index"
            :label="radio.label"
            >{{ radio.text }}</el-radio-button
          >
        </el-radio-group>

        <!-- 下拉框 -->

        <el-select
          v-if="item.type === 'select'"
          :placeholder="item.placeholder"
          v-model="driverOptions[item.name]"
        >
          <el-option
            v-for="(opt, index) in selectsOptions[item.name]"
            :key="index"
            :label="opt.label"
            :value="opt.value"
          ></el-option>
        </el-select>

        <!-- 普通输入框 -->

        <el-input
          v-if="item.type === 'input'"
          v-model="driverOptions[item.name]"
          style="width: 210px;"
        ></el-input>
        <a v-if="item.name=='projectId'" target="_blank" href="https://support.huaweicloud.com/api-cce/cce_02_0271.html"><p class="tips">如何获取?</p></a>
      </el-form-item>
    </el-form>
    <div id="zhanwei"></div>
    <PrevNext @prev-click="onPrevClick" @next-click="onNextClick"></PrevNext>
  </div>
</template>

<script>
import formObj from "../../../assets/formObj/huawei.js";
import PrevNext from "../../../components/Common/PrevNext.vue";
import { PROCESS_ACTIVE,DEFUALT_INPUT } from "../../../assets/constant.js";

export default {
  components: { PrevNext },
  // created() {
  //   this.$store.dispatch("setProcessActive", 2);
  // },
  // destroyed() {
  //   console.log('here: ' + this.$refs.driverOptions);
  // },

  created() {
    this.$store.dispatch("setProcessActive", PROCESS_ACTIVE.CLUSTER);
  },

  data() {
    return {
      formObjDriver: formObj.driverOptions,
      
      driverOptions: {
        billingMode: '0',
        clusterName: this.$store.getters.reqData?.driverOptions?.clusterName || "unamed",
        KubernetesVersion:
          this.$store.getters.reqData?.driverOptions?.KubernetesVersion || "v1.19",
        clusterSpec: this.$store.getters.reqData?.driverOptions?.clusterSpec  || "cce.s1.small",
        clusterType: this.$store.getters.reqData?.driverOptions?.clusterType || "CCE",
        projectId: this.$store.getters.reqData?.driverOptions?.projectId || "",
        regionId: this.$store.getters.reqData?.driverOptions?.regionId  || "cn-southwest-2",  
        vpcId: this.$store.getters.reqData?.driverOptions?.vpcId || "",
        subnetId: this.$store.getters.reqData?.driverOptions?.subnetId  || "7a15c471-6d7c-48b5-835c-16846cbfbba1",
        eipAddress:this.$store.getters.reqData?.driverOptions?.eipAddress  || "", 
        clusterSpecPlatform:
          DEFUALT_INPUT.clusterSpecPlatform,
        KubernetesProxyMode:
          DEFUALT_INPUT.KubernetesProxyMode,
        containerNetWorkMode:
          DEFUALT_INPUT.containerNetWorkMode,
      },
      selectsOptions: {
        clusterSpec: [
          {
            label: "50节点",
            value: "cce.s1.small",
          },
          {
            label: "200节点",
            value: "cce.s1.medium",
          }
        ],
        clusterType: [
          {
            label: "CCE",
            value: "CCE",
          },
          {
            label: "Turbo",
            value: "Turbo",
          }
        ],
        regionId: [
          {
            label: "西南-贵阳一",
            value: "cn-southwest-2",
          },
        ],

        // projectId: [
        //   {
        //     label: "数元灵",
        //     value: "0c8c4ca1bf80f2372f2cc003b2cf38bf",
        //   },
        // ],

        subnetId: [
          {
            label: "192.168.0.0/24",
            value: "7a15c471-6d7c-48b5-835c-16846cbfbba1",
          },
        ],

        billingMode: [
          {
            text: "包年/包月",
            label: "1",
          },
          {
            text: "按需计费",
            label: "0",
          },
        ],

        KubernetesVersion: [
          {
            text: "v1.19",
            label: "v1.19",
          },
          {
            text: "v1.21",
            label: "v1.21",
          },
        ],

        // vpcId: [
        //   {
        //     text: "VPC网络",
        //     label: "6fc61f3e-9295-41a2-b15e-6aaffa294e3a",
        //   },
        //   {
        //     text: "容器隧道网络",
        //     label: "6fc61f3e-9295-41a2-b15e-6aaffa294e3a",
        //   },
        // ],
      },
    };
  },
  methods: {
    onPrevClick() {
      this.$router.push("/form/huaweiAccess");
    },

    onNextClick(driverOptions) {
      this.$refs["driverOptions"].validate((valid) => {
        if (!!valid) {
          try {
            this.$store.commit("setDriverOptions", this.driverOptions);
            console.log(this.driverOptions);
            // axios.get("http://139.9.246.185:8080/api/driver/create").then((res) => {
            console.log("集群创建完成，即将进入节点创捷阶段...");
            console.log(JSON.stringify(this.$store.getters.reqData));
            // console.log('here: ' + this.$refs[driverOptions]);
            this.$router.push("/form/huaweiAccess/huaweiCluster/node");
            // });
          } catch (error) {
            console.log(error);
          }
        } else {
          ElMessage({
            showClose: true,
            message: "输入不符合规则，请重新输入",
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
#zhanwei{
  height: 50px;
}
</style>